import {Tooltip} from '@material-ui/core';
import { CardPessoaProps } from './card-pessoa-props';
import { useCallback } from 'react';
import { EnumCadastroStatus } from 'model/enums/enum-cadastro-status';
import { EditarIcon } from '../../icons/editar-icon';
import { StatusCadastroMock, TpCadastroFarmaciaMock } from '../../../../data/mocks';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../..';
import React from 'react';
import { useStyles } from './card-pessoa-styles'
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { Grid, Typography } from 'views/design-system';

const CardPessoa = ({ model, onClick, onCheck, selected }: CardPessoaProps) => {
  const classes = useDefaultCardStyles();
  const pessoaClasses = useStyles();
  const { theme, isMobile } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    switch (model.status) {
      case EnumCadastroStatus.LIBERADO:
        return theme.palette.success.main;
      case EnumCadastroStatus.BLOQUEADO:
        return theme.palette.warning.main;
      case EnumCadastroStatus.DESATIVADO:
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  }, [
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.error.main,
    theme.palette.primary.main,
    model.status,
  ]);

  const descricao = StatusCadastroMock.filter((x) => x.Key === model.status)?.[0]
    ?.Value;
  const tpcadastro = TpCadastroFarmaciaMock.filter((x) => x.Key === model.tpCadastro)?.[0]
    ?.Value;

  const nomeItem = useCallback(() => (
    <Grid item xs={12} md={6} className={pessoaClasses.item}>
      <Typography color='textSecondary' variant='caption'>
        Nome:
      </Typography>
      <Typography color='textPrimary'>
        {model.nome}
      </Typography>
    </Grid>
  ), [model.nome, pessoaClasses.item])

  const tipoItem = useCallback(() => (
    <Grid item xs={4} md={3} className={pessoaClasses.item}>
      <Typography color='textSecondary' variant='caption'>
        Tipo:
      </Typography>
      <Typography color='textPrimary'>
        {tpcadastro ?? 'Não encontrado'}
      </Typography>
    </Grid>
  ), [pessoaClasses.item, tpcadastro])

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model?.id ?? '');
        }}
      >
        <Tooltip arrow title={descricao ?? 'Não encontrado'} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classNames(classes.cardContent, pessoaClasses.card)}>
          <Grid container>
            <Grid item xs={8} md={3} className={pessoaClasses.item}>
              <Typography color='textSecondary' variant='caption'>
                CPF/CNPJ:
              </Typography>
              <Typography color='textPrimary'>
                {!isEmpty(model.cpfcnpj) ? formatarCPFCNPJ(model.cpfcnpj) : 'Não informado'}
              </Typography>
            </Grid>
            {isMobile ? (
              <>
                {tipoItem()}
                {nomeItem()}
              </>
            ) : (
              <>
                {nomeItem()}
                {tipoItem()}
              </>
            )}
          </Grid>
        </div>
        <Tooltip arrow title="Editar Pessoa">
          <div className={classNames(classes.cardRightButton, pessoaClasses.iconRight)}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

export default React.memo(CardPessoa);
