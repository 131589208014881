import {
  Box,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { toDecimalString } from 'utils/to-decimal';
import { DefaultCard } from 'views/components/cards/components';
import { useStyles } from './card-styles';
import { EnumTpSituacaoApuracao } from 'data/api/gestao/comissao/patch-campanha-apuracao-descondierar-comissionado';
import { useThemeQueries } from 'views/theme';
import {
  EnumTpStatusProcessamento,
  IComissionado
} from 'views/components/modals/comissao/comissao-modal/stores/types/IComissionado';

const enumTpSituacaoApuracaoToLabel = (situacao: EnumTpSituacaoApuracao) => {
  switch (situacao) {
    case EnumTpSituacaoApuracao.Pendente:
      return 'Pendente';
    case EnumTpSituacaoApuracao.Sucesso:
      return 'Confirmado';
    default:
      return 'Ignorado';
  }
};

export const ApuracaoCard = (props: {
  comissionado: Omit<IComissionado, 'vendas'>;
  onClick?: (comissionado: Omit<IComissionado, 'vendas'>) => void;
}) => {
  const cardClasses = useStyles();
  const { theme } = useThemeQueries();
  return (
    <>
      <DefaultCard
        disabled={
          props.comissionado.statusProcessamento ===
          EnumTpStatusProcessamento.Processando
        }
        onClick={async () => {
          if (
            props.onClick &&
            props.comissionado.statusProcessamento ===
              EnumTpStatusProcessamento.Concluido
          ) {
            props.onClick(props.comissionado);
          }
        }}
      >
        <Box
          p={1}
          style={{
            width: '100%'
          }}
        >
          <Grid container spacing={1} className={cardClasses.cardContent}>
            {props.comissionado.statusProcessamento ===
              EnumTpStatusProcessamento.Processando && (
              <Grid item xs={12}>
                <LinearProgress color="primary" />
              </Grid>
            )}
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '10px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="caption"
                    style={{
                      marginRight: '10px'
                    }}
                  >
                    Competência
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    display="inline"
                  >
                    {props.comissionado.dtComp}
                  </Typography>
                </div>
                <div>
                  <Chip
                    label={enumTpSituacaoApuracaoToLabel(
                      props.comissionado.situacao
                    )}
                    style={{
                      backgroundColor:
                        props.comissionado.situacao ===
                        EnumTpSituacaoApuracao.Pendente
                          ? theme.palette.warning.main
                          : theme.palette.success.main,
                      color: theme.palette.getContrastText(
                        props.comissionado.situacao ===
                          EnumTpSituacaoApuracao.Pendente
                          ? theme.palette.warning.main
                          : theme.palette.success.main
                      )
                    }}
                  />
                </div>
              </div>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex">
                <div className={cardClasses.celulaGrid}>
                  <Typography color="textPrimary" variant="caption">
                    Nome
                  </Typography>
                  <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                  >
                    {props.comissionado.nome}
                  </Typography>
                </div>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <div className={cardClasses.celulaGrid}>
                <Typography color="textPrimary" variant="caption">
                  Base de cálculo
                </Typography>
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                >
                  R${toDecimalString(props.comissionado.baseCalculo)}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={cardClasses.celulaGrid}>
                <Typography color="textPrimary" variant="caption">
                  Qtd Itens
                </Typography>
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                >
                  {props.comissionado.qtdItens}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={cardClasses.celulaGrid}>
                <Typography color="textPrimary" variant="caption">
                  Valor Comissão
                </Typography>
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                >
                  R${toDecimalString(props.comissionado.valorComissao)}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
      </DefaultCard>
    </>
  );
};
