import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { CardPessoaPreviewProps } from './card-pessoa-preview-props';
import { useCallback } from 'react';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import React from 'react';
import { useStyles } from './card-pessoa-preview-styles';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import {
  DefaultCard,
  useDefaultCardStyles
} from 'views/components/cards/components';
import { useThemeQueries } from 'views/theme';
import { EditarIcon, RecarregarIcon } from 'views/components/icons';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';
import { EnumTpCondicaoApuracao } from 'model/enums/enum-tp-condicao-apuracao';
import { CheckboxDefault } from 'views/components/controles/checkboxes';

const CardPessoaPreview = ({
  model,
  onAltera,
  tipo,
  edit,
  alterar,
  selecionar,
  selecionado,
  onSelect
}: CardPessoaPreviewProps) => {
  const classes = useDefaultCardStyles();
  const pessoaClasses = useStyles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    return theme.palette.primary.main;
  }, [theme.palette.primary.main]);

  const nomeItem = useCallback(
    () => (
      <Grid container>
        <Grid item xs className={pessoaClasses.item}>
          <Typography color="textSecondary" variant="caption">
            Nome:
          </Typography>
          <Typography color="textPrimary" style={{ wordBreak: 'break-all' }}>
            {model.nome}
          </Typography>
        </Grid>
        <Grid item xs="auto" className={pessoaClasses.item}>
          <Typography color="textSecondary" variant="caption">
            {model.condicao === EnumTpCondicaoApuracao.Percentual
              ? `Porcentagem (%)`
              : `Valor fixo (R$)`}
          </Typography>
          <Typography color="textPrimary">
            {model.condicao === EnumTpCondicaoApuracao.Percentual
              ? `${model.valor}%`
              : `R$ ${model.valor.toString().replace('.', ',')}`}
          </Typography>
        </Grid>
      </Grid>
    ),
    [model.condicao, model.nome, model.valor, pessoaClasses.item]
  );

  return (
    <>
      <DefaultCard
        isSelected={selecionado}
        onClick={async () => {
          edit && onAltera(model);
          selecionar && onSelect(model);
        }}
      >
        <div
          className={classes.tagStatus}
          style={{ background: retornarCorStatus() }}
        ></div>
        <div className={classNames(classes.cardContent, pessoaClasses.card)}>
          <Grid container>
            {nomeItem()}
            <Grid item xs={6} md={6} className={pessoaClasses.item}>
              <Typography color="textSecondary" variant="caption">
                CPF/CNPJ:
              </Typography>
              <Typography color="textPrimary">
                {!isEmpty(model.documento)
                  ? formatarCPFCNPJ(model.documento)
                  : 'Não informado'}
              </Typography>
            </Grid>
            {tipo === EnumTpPessoaComissionamento.Gerente && (
              <Grid item xs={6} md={6} className={pessoaClasses.item}>
                <Typography color="textSecondary" variant="caption">
                  Vinculados:
                </Typography>
                <Typography color="textPrimary">
                  {`${model.vendedores?.length ?? 0} ${
                    model.vendedores?.length === 1 ? 'Vendedor' : 'Vendedores'
                  }`}
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
        {edit && (
          <Tooltip arrow title="Alterar Comissionado">
            <div
              className={classNames(
                classes.cardRightButton,
                pessoaClasses.iconRight
              )}
            >
              <Button variant="text" style={{ marginLeft: 8, marginRight: 8 }}>
                <EditarIcon tipo="GERAL" />
              </Button>
            </div>
          </Tooltip>
        )}
        {selecionar && (
          <Tooltip arrow title="Alterar Comissionado">
            <div
              className={classNames(
                classes.cardRightButton,
                pessoaClasses.iconRight
              )}
            >
              <CheckboxDefault checked={selecionado} />
            </div>
          </Tooltip>
        )}

        {alterar && (
          <Tooltip arrow title="Alterar comissionado">
            <div
              className={classNames(
                classes.cardRightButton,
                pessoaClasses.iconRight
              )}
            >
              <div
                style={{
                  borderLeftWidth: '2px',
                  borderColor: '#E6E6E6',
                  borderStyle: 'solid',
                  height: '45px'
                }}
              />

              <Button
                variant="text"
                style={{ marginLeft: 8, marginRight: 8 }}
                onClick={(e) => {
                  onAltera(model);
                  e.stopPropagation();
                }}
              >
                <RecarregarIcon
                  tipo="GERAL"
                  fill={theme.palette.primary.main}
                />
              </Button>
            </div>
          </Tooltip>
        )}
      </DefaultCard>
    </>
  );
};

export default React.memo(CardPessoaPreview);
