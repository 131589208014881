import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { ProdutoResumidoModel } from "model/api/gestao/produto/produto/produto-resumido-model";
import { CardSelaecaoProdutosCodigos } from "views/components/cards/card-selecao-produtos-codigos/card-selecao-produtos-codigos";
import { ProdutoCodigoModel } from "model/api/gestao/produto/produto-codigo/produto-codigo-model";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { useCallback } from "react";
import { useMovProd } from "services/app/hooks/mov-prod";
import { useMovAtual } from "services/app/hooks/mov-atual";
import { isPlanoFarmaceutico } from "utils/plano-utils";
import { EnumMovModelo } from "model";
import { Grid } from "views/design-system";

export interface DialogSelecaoProdutosCodigosProps {
    aberto: boolean;
    prod: ProdutoResumidoModel;
    detailed?: boolean
}

export const DialogSelecaoProdutosCodigos = ({
    aberto,
    prod,
    detailed
}: DialogSelecaoProdutosCodigosProps) => {
    const { inserirProdutoByCodigo, inserirProdutoFromSearchDetailed } = useMovProd();
    const { fecharSelecaoProdutosCodigoDialog } = useCadastros();
    const { showToast } = useToastSaurus();
    const { getMov } = useMovAtual();
    const { plano } = useSessaoAtual();

    const onClick = useCallback(async (model: ProdutoCodigoModel) => {
        try {
            if (
                ((!model.fator || (model.fator && model.fator === 1)) && detailed) ||
                (isPlanoFarmaceutico(plano?.plano) && (getMov()?.mod === EnumMovModelo.PEDIDO || getMov()?.mod === EnumMovModelo.DELIVERY))
            ) {
                await inserirProdutoFromSearchDetailed(prod, model)
                fecharSelecaoProdutosCodigoDialog();
                return
            }
            await inserirProdutoByCodigo(model.codigo);
            fecharSelecaoProdutosCodigoDialog();
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [detailed, fecharSelecaoProdutosCodigoDialog, getMov, inserirProdutoByCodigo, inserirProdutoFromSearchDetailed, plano?.plano, prod, showToast])

    return (
        <DialogSaurus
            aberto={aberto || false}
            titulo="Selecione o Código"
            tamanho="xs"
            isButtonTitleClose
            handleClickClose={fecharSelecaoProdutosCodigoDialog}
        >
            <Grid container spacing={2} style={{ paddingBottom: '24px' }}>
                {prod.codigos?.map((item) => {
                    return (
                        <Grid item xs={12}>
                            <CardSelaecaoProdutosCodigos
                                model={item}
                                produto={prod}
                                onClick={onClick}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </DialogSaurus>
    )
}