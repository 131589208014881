import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useGetPessoas } from 'data/api/gestao/pessoa';
import { useStyles } from './pessoa-list-styles';
import { PessoaListData } from './pessoa-list-data';
import { PessoaModel } from 'model/api/gestao/pessoa/pessoa-model';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { EnumCadastroStatus } from 'model';
import { Grid } from 'views/design-system';

export const PessoaList = () => {
  const classes = useStyles();
  const { abrirCadastroPessoa } = useCadastros();
  const { getPessoas, carregando } = useGetPessoas();
  const { addHandler, removeHandler } = useEventTools();

  const { showToast } = useToastSaurus();
  const history = useHistory();
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PessoaModel>(),
  });
  const [modalEditAberto, setModalEditAberto] = useState(false);

  const [selectedList, setSelectedList] = useState<Array<string>>([]);


  const filtros = useMemo(() => {
    const urlParams = new URLSearchParams(history.location.search)
    return {
      status: isEmpty(urlParams.get('status')) ? EnumCadastroStatus.LIBERADO : Number(urlParams.get('status')),
      termo: urlParams.get('termo') || '',
      tpCadastro: !isEmpty(urlParams.getAll('tpCadastro')) ? urlParams.getAll('tpCadastro').map(tp => Number(tp)) : [-1],
    }
  }, [history.location.search])

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PessoaModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const search = useCallback(
    async (newPage: number) => {
      const query =
        '' +
        (!isEmpty(filtros.termo)
          ? 'generico=' + filtros.termo
          : '') +
        (filtros.status > -1
          ? '&status=' + filtros.status
          : '') +
        (filtros.tpCadastro.length > 0
          ? filtros.tpCadastro.find(t => t === -1) ? '' : filtros.tpCadastro.reduce((acc, current) => acc + `&TpCadastros=${current}`, '')
          : '');

      try {
        const res = await getPessoas(query, newPage);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [filtros.termo, filtros.status, filtros.tpCadastro, getPessoas, fillResult, showToast],
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned);
  }, []);

  useEffect(() => {
    if (!modalEditAberto) {
      search(queryStatus.page);
    }

    addHandler(AppEventEnum.PessoaModal, modalEdit);

    return () => removeHandler(AppEventEnum.PessoaModal, modalEdit);
  }, [
    addHandler,
    modalEdit,
    modalEditAberto,
    queryStatus.page,
    removeHandler,
    search,
  ]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  const onCardSelected = (id: string) => {
    abrirCadastroPessoa(id, history.location.pathname, true);
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <PessoaListData
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
