import { useCallback, useMemo } from "react";
import { useDefaultCadastroStyles } from "../components/default-cadastro-styles";
import { ButtonFab } from "views/components/controles";
import { Grid } from "@material-ui/core";
import { MarcaHeader } from "./components/marca-header/marca-header";
import { NovoIcon } from "views/components/icons";
import { useCadastros, useSessaoAtual } from "services/app";
import { MarcaList } from "./components/marca-list/marca-list";
import { nomeMarcaAmbiente } from "utils/nome-marca-ambiente";

const MarcaPage = () => {
    const classes = useDefaultCadastroStyles();
    const { plano } = useSessaoAtual()

    const { abrirCadastroMarca } = useCadastros();

    const onClickAdd = useCallback(() => {
        abrirCadastroMarca("", "", true);
    }, [abrirCadastroMarca]);

    const component = useMemo(() => <Grid className={classes.root}>
        <Grid className={classes.header}>
            <MarcaHeader />
        </Grid>
        <Grid className={classes.list}>
            <MarcaList />
        </Grid>
        <ButtonFab
            tooltip={`Adicionar ${nomeMarcaAmbiente(plano?.plano)}`}
            icon={<NovoIcon tipo="BUTTON_FAB" />}
            onClick={onClickAdd}
        />
    </Grid>, [classes.header, classes.list, classes.root, onClickAdd, plano?.plano]);

    return component;
};

export default MarcaPage;
