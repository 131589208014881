import { Tooltip, Typography } from '@material-ui/core';
import { CardImpressoraProps } from './card-marca-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from 'views/theme';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';

export const CardMarca = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardImpressoraProps) => {
  const classes = useDefaultCardStyles();
  const { isMobile } = useThemeQueries()

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={async () => {
          onClick(model.id ?? '');
        }}
      >
        <div className={classes.cardContent}>
          <div
            className={isMobile ? classes.celulaGridFull : classes.celulaGrid}
            style={{ whiteSpace: 'break-spaces', minWidth: 250, maxWidth: isMobile ? 'auto' : 250 }}
          >
            <Typography variant="caption">
              Nome
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>
                {model.nome}
              </strong>
            </Typography>
          </div>
          {model?.cnpjFab && model.cnpjFab.length > 0 && (
            <div
              className={classes.celulaGrid}
              style={{ whiteSpace: 'break-spaces', minWidth: 150 }}
            >
              <Typography variant="caption">
                CNPJ
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {formatarCPFCNPJ(model?.cnpjFab ?? '')}
              </Typography>
            </div>
          )}
        </div>
        <Tooltip arrow title="Editar Marca">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
