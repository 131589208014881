import { makeStyles } from "@material-ui/core";

export const useDefaultCardStyles = makeStyles((theme) => ({
  cardContainer: {
    overflow: 'hidden',
    padding: 0,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "transparent",
    transition: "0.3s ease-in-out border",
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    minHeight: "60px",
    position: "relative",
    marginTop: "5px",
    paddingLeft: 4,
  },
  cardWithoutTag: {
    paddingLeft: 0,
  },
  cardSelected: {
    borderColor: theme.palette.primary.main,
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  tagStatus: {
    width: 4,
    height: "100%",
    borderRadius: "8px 0 0 8px",
    margin: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: -1,
    position: "absolute",
    top: 0,
    left: 0,
  },
  cardIcon: {
    width: "50px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 0,
      width: "22px",
      height: "22px",
      fill: theme.palette.primary.main,
    },
  },
  cardColor: {
    width: "25px",
    height: "25px",
    borderRadius: "5px",
    borderColor: theme.palette.grey[700],
    borderStyle: "solid",
    marginBottom: 10,
    margin: 0,
    boxShadow: theme.shadows[6],
  },
  cardRightButton: {
    width: "50px",
    borderLeftWidth: 2,
    borderLeftStyle: "solid",
    borderLeftColor: '#E6E6E6',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& svg": {
      margin: 0,
      width: "22px",
      height: "22px",
      fill: theme.palette.primary.main,
    },
  },
  cardRightButtonProcesso: {
    width: "50px",
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.text.primary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 0,
      width: "22px",
      height: "22px",
      fill: theme.palette.text.primary,
    },
  },
  cardContent: {
    flex: "1 1 100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: theme.spacing(1),
    // "& div:last-child": {
    //   marginRight: theme.spacing(1),
    // },
    "& span": {
      fontSize: "0.7em",
    },
    "& p": {
      fontSize: "1.0em",
    },
  },
  cardContentMobile: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: 'column',
    paddingRight: theme.spacing(1),
    paddingTop: "3px",
    // "& div:last-child": {
    //   marginRight: theme.spacing(1),
    // },
    "& span": {
      fontSize: "0.7em",
    },
    "& p": {
      fontSize: "1.0em",
    },
  },
  celulaGrid: {
    marginLeft: theme.spacing(1),
    minWidth: 0,
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },
  celulaGridFull: {
    marginLeft: theme.spacing(1),
    flex: "1 1 auto",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },

  celulaGridFullDescriptionFull: {
    marginLeft: theme.spacing(1),
    flex: "1 1 auto",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "& p": {
      maxWidth: '600px',
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: 'ellipsis',
      fontWeight: 600
    }
  },

  celulaGridFullMobile: {
    marginLeft: theme.spacing(1),
    flex: "1 1 auto",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& p": {
      maxWidth: '270px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontWeight: 600
    }
  },

  celulaGridFullMobileDialog: {
    marginLeft: theme.spacing(1),
    flex: "1 1 auto",
    flexWrap: "wrap",

    overflow: "hidden",
    "& p": {
      overflow: 'hidden',
    }
  },

  cardImg: {
    display: "flex",
    objectFit: "cover",
    zIndex: 1,
    position: 'relative',
    background: theme.palette.common.white,
    width: "50px",
    height: "50px",
    borderRadius: "5px",
  },
  cardinativo: {
    opacity: 0.6,
    filter: 'grayscale(1)',
  },
  celulaGridDescricao: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
    minWidth: 0,
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },
  pointer: {
    cursor: 'pointer'
  }
}));

