import { Box, Grid, Typography } from 'views/design-system';
import { useStyles } from './mov-processando-pagamento-styles';
import { useThemeQueries } from 'views';
import { useCallback, useEffect, useRef } from 'react';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { useHistory, useLocation } from 'react-router-dom';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { useCadastros, useToastSaurus } from 'services/app';
import { CredenciamentoSafra } from 'model/api/gestao/finalizadora/finalizadora-model';
import { ConvenioCartaoAutenticarModel, ConvenioSolicitarCreditoPostModel } from 'model/api/gestao/convenio';
import { usePostConvenioSolicitarCredito } from 'data/api/gestao/conta-pessoa/post-convenio-solicitar-credito';
import { newGuid } from 'utils/new-guid';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { ConvenioSolicitarCreditoModel } from 'model/api/gestao/convenio/convenio-solicitar-credito-model';
import { EnumPagTpMod } from 'model';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { usePagamentoFaturaConvenio } from 'services/app/hooks/pagamento-fatura-convenio';
import { impressaoParcelasGuilhotina } from 'utils/impressao-parcelas-guilhotina';
import { roundTo } from 'utils/round-to';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useAguardandoPagamentoStore } from './components/aguardando-pagamento/aguardando-pagamento-store';

export interface MovProcessandoPageLocationProps {
    modoPagamento: MovSimplesPagamentoModel;
    credenciais: CredenciamentoSafra
    credenciado: boolean
    loginConvenio?: ConvenioCartaoAutenticarModel
    pagFatura?: {
        faturaId: string
        anoMesCompetencia: number
        pessoaId: string
    }
}

const MovProcessandoPagamentoPage = () => {
    const classes = useStyles();
    const { isMobile } = useThemeQueries();
    const location = useLocation<MovProcessandoPageLocationProps>()
    const { showToast } = useToastSaurus()
    const { getEmpresaAtual } = useEmpresaAtual()
    const { iniciarPagamento, getMov, saveQtdPessoasPagamento, getQtdPessoasPagamento, verificaProdutosControlados } = useMovAtual()
    const { iniciarPagamentoFatura, handlePagamento } = usePagamentoFaturaConvenio()
    const { postConvenioSolicitarCredito } = usePostConvenioSolicitarCredito()
    const { abrirConvenioResumoFaturas } = useCadastros()
    const {
        modoPagamento,
        credenciais,
        loginConvenio,
        pagFatura,
        credenciado
    } = location.state
    const textTitle = useRef<HTMLParagraphElement>(null)
    const { getConfigByCod } = usePDV()
    const { avancarFluxoMov, redirectPagamentoAvancado } = useMovRota();
    const { abrirConvenioAutenticar } = useCadastros()
    const qtdeColunas = getConfigByCod(EnumPDVConfigCod.QuantidadeColunas)
    const history = useHistory()

    const { mensagem: mensagemPOS, processando } = useAguardandoPagamentoStore(state => state.props);

    const feedBackPagamento = useCallback((text: any) => {
        if (text.detail && textTitle.current) {
            textTitle.current.textContent = text.detail
        }
    }, [])

    useEffect(() => {
        window.addEventListener('PointPayment.FeedBack', feedBackPagamento, false);

        return () => {
            window.removeEventListener('PointPayment.FeedBack', feedBackPagamento);
        };
    }, [feedBackPagamento]);

    const mensagemFeedBack = useCallback((mensagem: string) => ({
        detail: mensagem
    }), [])

    const solicitarCredito = useCallback(async () => {
        const mov = getMov()
        if (!loginConvenio) return
        if (!mov) return

        feedBackPagamento(mensagemFeedBack('SOLICITANDO CRÉDITO DO CARTÃO'))

        const data: ConvenioSolicitarCreditoPostModel = {
            ...new ConvenioSolicitarCreditoPostModel(),
            caixaId: mov.operadorId,
            cartao: {
                ...loginConvenio,
            },
            tefId: newGuid(),
            valor: modoPagamento.vPag,
            quantidadeParcelas: modoPagamento.nParcelas === 0 ? 1 : modoPagamento.nParcelas,
            colImpViaCliente: Number(qtdeColunas ?? '50')
        }

        const res = await postConvenioSolicitarCredito(data, getEmpresaAtual()?.id ?? '')
        if (res.erro) {
            abrirConvenioAutenticar('venda', {
                pagamento: modoPagamento,
                credenciais: credenciais

            })
            throw res.erro
        }

        return res.resultado?.data as ConvenioSolicitarCreditoModel
    }, [abrirConvenioAutenticar, credenciais, feedBackPagamento, getEmpresaAtual, getMov, loginConvenio, mensagemFeedBack, modoPagamento, postConvenioSolicitarCredito, qtdeColunas])

    const processarPagamento = useCallback(async () => {
        try {
            let creditoLoja: ConvenioSolicitarCreditoModel | undefined
            if (modoPagamento.modPagamento === EnumPagTpMod.CREDITO_LOJA) {
                creditoLoja = await solicitarCredito()

                if (!creditoLoja) return
                modoPagamento.tefId = creditoLoja.tefId
                modoPagamento.cAut = creditoLoja.codigoAutorizacao
                modoPagamento.viaCliente = creditoLoja.viaImpressao ?? ''
                modoPagamento.viaLojista = impressaoParcelasGuilhotina(creditoLoja.parcelas.map(x => x.viaImpressao))

            }

            if (pagFatura) {
                const pagamento: any = await iniciarPagamentoFatura({
                    credenciamento: credenciais,
                    pagamento: modoPagamento,
                    credenciado
                })

                const { anoMesCompetencia, faturaId } = pagFatura

                feedBackPagamento(mensagemFeedBack('PROCESSANDO PAGAMENTO DA FATURA'))

                await handlePagamento({
                    anoMesCompetencia: String(anoMesCompetencia), faturaId, pagamento: {
                        ...pagamento,
                        vPag: roundTo(pagamento.vPag, 2)
                    }
                })
                return history.goBack()
            }

            await iniciarPagamento({
                pagamento: modoPagamento,
                naoAlteraTaxa: true,
                credenciamento: credenciais
            });
            const objQtdPessoas = getQtdPessoasPagamento()
            const qtdePessoas = objQtdPessoas.pessoas ? objQtdPessoas.pessoas : 1

            const novaQtde = qtdePessoas > 1 ? qtdePessoas - 1 : 1
            saveQtdPessoasPagamento(novaQtde);

            const medicamentos = await verificaProdutosControlados()

            const qtd = medicamentos.reduce((acc, current) => acc + current.qCom, 0);
            if (qtd > 0) {

                feedBackPagamento(mensagemFeedBack('VALIDANDO PRODUTOS CONTROLADOS'))
            }

            await avancarFluxoMov();
        } catch (err: any) {
            if (pagFatura) {
                abrirConvenioResumoFaturas({ pessoaId: pagFatura.pessoaId, anoMesSelecionado: pagFatura.anoMesCompetencia })
                showToast('error', err.message);
                return history.goBack()
            }
            try {
                await redirectPagamentoAvancado();
            } catch { }

            showToast('error', err.message);
        }
    }, [abrirConvenioResumoFaturas, avancarFluxoMov, credenciado, credenciais, feedBackPagamento, getQtdPessoasPagamento, handlePagamento, history, iniciarPagamento, iniciarPagamentoFatura, mensagemFeedBack, modoPagamento, pagFatura, redirectPagamentoAvancado, saveQtdPessoasPagamento, showToast, solicitarCredito, verificaProdutosControlados])

    const setHeaderProps = useVendaHeaderStore((state) => state.setHeaderProps);

    useEffect(() => {
        setHeaderProps({
            title: 'Processando pagamento',
        })
        processarPagamento()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retMensagem = () => {
        if (processando) {
            if (mensagemPOS.length > 0) {
                return mensagemPOS.map((msg, i) => {
                    let text: string | JSX.Element = msg.mensagem;
                    if (msg.isInput) {
                        text = <Box className={classes.senhaContainer}>
                            {msg.mensagem}
                        </Box>
                    }
                    return <>
                        {text}{i < mensagemPOS.length - 1 ? <br /> : ''}
                    </>
                })
            }
        }
        return 'PROCESSANDO PAGAMENTO'
    }

    return (
        <>
            <Grid
                className={`${classes.container} ${classes.containerBackgroundColor}`}
            >
                <Grid className={classes.content}>
                    <Grid
                        className={
                            isMobile ? classes.infoContainer : classes.infoContainerPC
                        }
                    >
                        <Typography ref={textTitle} className={classes.textTitle}>
                            {retMensagem()}
                        </Typography>
                        <Grid className={classes.containerLoading}>
                            <LoadingFinalizacao />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    );
};

export default MovProcessandoPagamentoPage;