import { DinheiroIcon, EditarIcon, GarcomIcon, InformacaoIcon } from 'views/components/icons';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { usePDV } from 'services/app/hooks/pdv';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { formatDecimalInteger, toDecimalString } from 'utils/to-decimal';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { roundTo } from 'utils/round-to';
import { isPlanoFarmaceutico, isPlanoPromocoes } from 'utils/plano-utils';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { Button, Grid, Typography } from 'views/design-system';
import { useThemeQueries } from 'views/theme';
import { useStyles } from './carrinho-totais-styles';
import { PromocaoIcon } from 'views/components/icons/promocao-icon';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { CimaIcon } from 'views/components/icons/cima-icon';
import { EnumMovModelo } from 'model';
import { DeliveryIcon } from 'views/components/icons/delivery-icon';
import { useMovRota } from 'services/app/hooks/mov-rota';


interface CarrinhoTotaisProps {
    telaDePagamento: boolean;
}

export const CarrinhoTotais = ({
    telaDePagamento = false
}: CarrinhoTotaisProps) => {
    // STATES E REFS
    const refValorOriginal = useRef<HTMLParagraphElement>(null);
    const refAPagar = useRef<HTMLParagraphElement>(null);
    const refQCom = useRef<HTMLParagraphElement>(null);
    const refInfAdicional = useRef<HTMLParagraphElement>(null);
    const refAcrescDescLabel = useRef<HTMLParagraphElement>(null);
    const refAcrescDescDiv = useRef<HTMLDivElement>(null);
    const refAcrescDesc = useRef<HTMLParagraphElement>(null);
    const refTaxa = useRef<HTMLParagraphElement>(null);
    const refTaxaEntrega = useRef<HTMLParagraphElement>(null);

    const { getMov, hasTaxaServicoProduto, hasTaxaEntregaProduto } = useMovAtual();
    const {
        abrirDialogInformacaoAdicional,
        abrirDialogTaxaServicoCarrinho
    } = useCadastros();
    const { xs } = useThemeQueries();
    // HOOKS
    const { addHandler, removeHandler } = useEventTools();
    const { getConfigByCod: getPdvConfigByCod } = usePDV();
    const { getConfigByCod: getContratoConfigByCod } = useContratoAtual();
    const { plano } = useSessaoAtual();
    const { showToast } = useToastSaurus();
    const { redirectVendaFrete } = useMovRota();

    // AUX
    const classes = useStyles();

    const modeloTrabalho = getPdvConfigByCod(101);
    const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);
    const isPromocao = isPlanoPromocoes(plano?.plano);
    const exibirServico = !isFarmaceutico && hasTaxaServicoProduto();
    const exibirTaxaEntrega = hasTaxaEntregaProduto();
    const [exibirOutrosValores, setExibirOutrosValores] = useState(!xs);

    const preencherTela = useCallback(() => {
        const isSomentePedido = [EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS].includes(modeloTrabalho as EnumModeloDeTrabalho);

        const mov = getMov();

        const valorAPagar = (mov?.vNF ?? 0) - (mov?.vPago ?? 0);
        const vDescAcresc = (mov?.vNF ?? 0) - (mov?.vProdMax ?? 0);

        let valorTaxaServico = 0;
        mov?.produtos
            .filter((x) => x.ativo && x.indFin)
            .forEach((x) => {
                if (x.produtoId === getContratoConfigByCod(EnumContratoConfig.ProdutoServico))
                    valorTaxaServico += x.vFinal;
            });

        let quantidade = mov?.produtos
            .filter((x) => x.ativo && x.indFin && x.produtoId !== getContratoConfigByCod(EnumContratoConfig.ProdutoServico))
            .reduce((prev, curr) => prev + (curr.qComModificador > 0 ? curr.qComModificador : curr.qCom), 0) ?? 0

        const prodTaxaEntrega = mov?.produtos.find(x => x.produtoId === getContratoConfigByCod(EnumContratoConfig.ProdutoEntrega));
        const vProdTaxaServico = (mov?.produtos ?? [])
            .filter(x => x.produtoId === getContratoConfigByCod(EnumContratoConfig.ProdutoServico) && x.ativo)
            .reduce((prev, curr) => prev + curr.vFinal, 0)

        let vNF = mov?.vProdMax ?? 0;
        if (isSomentePedido || mov?.mod === EnumMovModelo.DELIVERY) {
            vNF = vNF - (prodTaxaEntrega?.vFinal ?? 0);
        }


        vNF = vNF - (vProdTaxaServico ?? 0)

        //PREENCHE O VALOR A PAGAR
        if (refAPagar.current)
            refAPagar.current.textContent = `R$ ${toDecimalString(valorAPagar, 2)} `;

        if (refAcrescDescLabel.current)
            refAcrescDescLabel.current.textContent = vDescAcresc > 0 ? 'Acréscimo' : 'Desconto'

        if (refAcrescDesc.current)
            refAcrescDesc.current.textContent = `R$ ${toDecimalString(Math.abs(vDescAcresc), 2)}`

        if (refAcrescDescDiv.current)
            refAcrescDescDiv.current.style['display'] = vDescAcresc !== 0 || isPromocao || exibirOutrosValores ? 'block' : 'none';

        if (refTaxa.current)
            refTaxa.current.textContent = `R$ ${toDecimalString(roundTo(valorTaxaServico))}`;

        if (refTaxaEntrega.current)
            refTaxaEntrega.current.textContent = `R$ ${toDecimalString(roundTo(prodTaxaEntrega?.vFinal ?? 0))}`;

        if (refQCom.current)
            refQCom.current.textContent = formatDecimalInteger(quantidade, 3) + (quantidade === 1 ? ' item' : ' itens');

        if (refValorOriginal.current)
            refValorOriginal.current.textContent = `R$ ${toDecimalString(roundTo(vNF))}`;

        if (refInfAdicional.current && exibirOutrosValores)
            refInfAdicional.current.textContent = isEmpty(mov?.infAdicional ?? '') ? '-' : ((mov?.infAdicional?.length ?? 0) > 10 ? (mov?.infAdicional?.substring(0, 30) + '...') : mov?.infAdicional ?? '');

    }, [exibirOutrosValores, getContratoConfigByCod, getMov, isPromocao, modeloTrabalho]);


    useEffect(() => {
        addHandler(AppEventEnum.MovAtualProdAlterado, preencherTela);
        addHandler(AppEventEnum.MovAtualAlterada, preencherTela);
        addHandler(AppEventEnum.PagamentoEfetuado, preencherTela);
        addHandler(AppEventEnum.AlterarDisplayKeybordPayment, preencherTela)

        return () => {
            removeHandler(AppEventEnum.MovAtualProdAlterado, preencherTela);
            removeHandler(AppEventEnum.MovAtualAlterada, preencherTela);
            removeHandler(AppEventEnum.PagamentoEfetuado, preencherTela);
            removeHandler(AppEventEnum.AlterarDisplayKeybordPayment, preencherTela)
        };
    }, [addHandler, preencherTela, removeHandler]);

    useEffect(() => {
        preencherTela();
    }, [preencherTela])

    return (
        <>
            <Grid className={classes.header}>
                <Grid className={classNames(classes.headerPartUm, telaDePagamento ? classes.headerTelaPagamento : undefined)}>

                    {!telaDePagamento && exibirOutrosValores && (
                        <Grid className={classNames(classes.headerItemContainerEditavel, classes.informacoes)} >
                            <Grid className={classes.headerItemContainer} style={{ alignItems: 'flex-start' }}>
                                <Grid className={classes.headerItem}>
                                    <InformacaoIcon
                                        tipo="BUTTON"
                                    />
                                    <Typography className={classes.labelText}>Informações</Typography>
                                </Grid>
                                <Grid className={classes.headerItem}>
                                    <Typography ref={refInfAdicional} align='left' style={{ fontSize: 10 }} className={classes.labelValor}>...</Typography>
                                </Grid>
                            </Grid>
                            <Button tabIndex={-1} className={classes.botaoRedondo} variant='contained' size='small' rounded onClick={() => {
                                abrirDialogInformacaoAdicional('', false);
                            }}>
                                <EditarIcon tipo='BUTTON' />
                            </Button>
                        </Grid>
                    )}
                    <Grid className={classNames(classes.headerItemContainer, classes.total)} pl={2}>
                        <Grid className={classes.headerItem}>
                            <DinheiroIcon
                                tipo="BUTTON"
                            />
                            <Typography className={classes.labelText} ref={refQCom}>Total</Typography>
                        </Grid>
                        <Grid className={classes.headerItem}>
                            <Typography ref={refValorOriginal} className={classNames(classes.labelValor, classes.labelValorDestaque)}>
                                ...
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid ref={refAcrescDescDiv} style={{ display: 'none' }} className={classNames(classes.headerItemContainer, classes.desconto)}>
                        <Grid className={classes.headerItem}>
                            <PromocaoIcon tipo="BUTTON" />
                            <Typography ref={refAcrescDescLabel} className={classes.labelText}></Typography>
                        </Grid>
                        <Grid className={classes.headerItem}>
                            <Typography ref={refAcrescDesc} className={classes.labelValor}>...</Typography>
                        </Grid>
                    </Grid>
                    {exibirServico && exibirOutrosValores && !telaDePagamento && (
                        <Grid className={classNames(classes.headerItemContainerEditavel, classes.servico)} >
                            <Grid className={classes.headerItemContainer}>
                                <Grid className={classes.headerItem}>
                                    <GarcomIcon
                                        tipo="BUTTON"
                                    />
                                    <Typography className={classes.labelText}>Serviço</Typography>
                                </Grid>
                                <Grid className={classes.headerItem}>
                                    <Typography ref={refTaxa} className={classes.labelValor}>...</Typography>
                                </Grid>
                            </Grid>
                            {!telaDePagamento && (
                                <Button tabIndex={-1} className={classes.botaoRedondo} variant='contained' size='small' rounded onClick={
                                    () => {
                                        if (
                                            (
                                                getMov()?.produtos.filter(
                                                    (x) =>
                                                        x.produtoId ===
                                                        getContratoConfigByCod(EnumContratoConfig.ProdutoServico)
                                                ) ?? []
                                            ).length <= 0
                                        ) {
                                            showToast('error', 'Não existe produtos para parametrizar a Taxa de Serviços.');
                                            return;
                                        }
                                        abrirDialogTaxaServicoCarrinho();
                                    }
                                }>
                                    <EditarIcon tipo='BUTTON' />
                                </Button>
                            )}
                        </Grid>
                    )}
                    {exibirTaxaEntrega && exibirOutrosValores && !telaDePagamento && (
                        <Grid className={classNames(classes.headerItemContainerEditavel, classes.servico)} >
                            <Grid className={classes.headerItemContainer}>
                                <Grid className={classes.headerItem}>
                                    <DeliveryIcon
                                        tipo="BUTTON"
                                    />
                                    <Typography className={classes.labelText}>Entrega</Typography>
                                </Grid>
                                <Grid className={classes.headerItem}>
                                    <Typography ref={refTaxaEntrega} className={classes.labelValor}>...</Typography>
                                </Grid>
                            </Grid>
                            {!telaDePagamento && (
                                <Button tabIndex={-1} className={classes.botaoRedondo} variant='contained' size='small' rounded onClick={
                                    async () => {
                                        if (
                                            (
                                                getMov()?.produtos.filter(
                                                    (x) =>
                                                        x.produtoId ===
                                                        getContratoConfigByCod(EnumContratoConfig.ProdutoEntrega)
                                                ) ?? []
                                            ).length <= 0
                                        ) {
                                            showToast('error', 'Não existe produtos para parametrizar a Taxa de Entrega.');
                                            return;
                                        }
                                        await redirectVendaFrete();
                                    }
                                }>
                                    <EditarIcon tipo='BUTTON' />
                                </Button>
                            )}
                        </Grid>
                    )}
                    {!telaDePagamento && (
                        <Grid className={classes.containerBotaoExpandir}>
                            <Button tabIndex={-1} className={classes.botaoRedondo} variant='contained' size='small' rounded onClick={() => { setExibirOutrosValores(!exibirOutrosValores) }}>
                                {exibirOutrosValores ?
                                    <CimaIcon tipo='BUTTON' style={{ transform: 'rotate(180deg)' }} />
                                    :
                                    <CimaIcon tipo='BUTTON' />
                                }
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid className={classes.headerPartDois}>
                    <Grid className={classes.headerItemContainerEditavel} >
                        <Grid className={classes.headerItemContainer}>
                            <Grid className={classes.headerItem}>
                                <SacoDinheiroIcon
                                    tipo="BUTTON"
                                />
                                <Typography className={classes.labelText}>À Pagar</Typography>
                            </Grid>
                            <Grid className={classes.headerItem}>
                                <Typography
                                    ref={refAPagar}
                                    className={classNames(classes.labelValor, classes.labelValorDestaque)}
                                    style={{ fontSize: 18 }}
                                >
                                    ...
                                </Typography>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    );
};
