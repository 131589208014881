import { useCallback, useMemo } from 'react';
import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { Grid } from 'views/design-system';
import { useThemeQueries } from 'views/theme';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { PrivatePageHeader } from 'views/components/headers';
import { DepositoIcon, MarcaIcon, MenuIcon } from 'views/components/icons';
import { CardCadastros } from '../../../../components/cards/card-cadastros/card-cadastros';
import { CategoriaIcon, ModificadoresIcon } from '../../../../components/icons';
import { NcmIcon } from '../../../../components/icons/ncm-icon';
import { ImpostoIcon } from '../../../../components/icons/imposto-icon';
import {
  isPlanoComEstoque,
  isPlanoFarmaceutico,
  isPlanoFiscal,
  isPlanoModificador
} from 'utils/plano-utils';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import { useSessaoAtual } from 'services/app';
import { InsumosIcon } from 'views/components/icons/insumo-icon';
import { ComboIcon } from 'views/components/icons/combo-icon';
import { nomeMarcaAmbiente } from 'utils/nome-marca-ambiente';
import { LaboratorioIcon } from 'views/components/icons/laboratorio-icon';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';

export const OutrosCadastrosPage = () => {
  const classes = useDefaultCadastroStyles();

  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const { plano, getPermissaoBoolean } = useSessaoAtual();
  const planoComEstoque = isPlanoComEstoque(plano?.plano);
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, abrirMenu]
  );

  const permissoes = useMemo(() => {
    const temNCM = getPermissaoBoolean(EnumCodigosPermissoes.NCMS);
    const temCategorias = getPermissaoBoolean(EnumCodigosPermissoes.CATEGORIAS_PRODUTOS);
    const temImpostos = getPermissaoBoolean(EnumCodigosPermissoes.IMPOSTOS);
    const temModificadores = getPermissaoBoolean(EnumCodigosPermissoes.MODIFICADORES);
    const temProdutos = getPermissaoBoolean(EnumCodigosPermissoes.PRODUTOS);
    const temEstoques = getPermissaoBoolean(EnumCodigosPermissoes.ESTOQUE);
    const temMarcas = getPermissaoBoolean(EnumCodigosPermissoes.MARCAS);

    return {
      temNCM,
      temCategorias,
      temImpostos,
      temModificadores,
      temProdutos,
      temEstoques,
      temMarcas
    };
  }, [getPermissaoBoolean]);

  const component = useMemo(() => <Grid className={classes.root}>
    <Grid className={classes.header}>
      <PrivatePageHeader title="Outros Cadastros" leftArea={leftArea()} />
    </Grid>
    <Grid p={1} spacing={1} container className={classes.list}>
      {permissoes.temCategorias ? (
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <CardCadastros
            rota="/categorias"
            titulo={'Categorias'}
            icone={CategoriaIcon}
          />
        </Grid>
      ) : null}
      {permissoes.temProdutos && (
        <>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <CardCadastros
              rota="/insumos"
              titulo="Insumos"
              icone={InsumosIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <CardCadastros
              rota="/combos"
              titulo={isPlanoFarmaceutico(plano?.plano) ? 'Kits e Combos' : 'Combos'}
              icone={ComboIcon}
            />
          </Grid>
        </>
      )}
      {planoComEstoque && permissoes.temEstoques && (
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <CardCadastros
            rota="/depositos"
            titulo={'Depósitos'}
            icone={DepositoIcon}
          />
        </Grid>
      )}
      {!isPlanoModificador(plano?.plano) && permissoes.temModificadores && (
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <CardCadastros
            rota="/modificadores"
            titulo="Modificadores"
            icone={ModificadoresIcon}
          />
        </Grid>
      )}
      {isPlanoFiscal(plano?.plano) && (
        <>
          {permissoes.temNCM ? (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CardCadastros rota="/ncms" titulo="NCM" icone={NcmIcon} />
            </Grid>
          ) : null}
          {permissoes.temImpostos ? (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CardCadastros
                rota="/impostos"
                titulo="Impostos"
                icone={ImpostoIcon}
              />
            </Grid>
          ) : null}
        </>
      )}
      {permissoes.temMarcas &&
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <CardCadastros
            rota="/marcas"
            titulo={nomeMarcaAmbiente(plano?.plano, true, true)}
            icone={isFarmaceutico ? LaboratorioIcon : MarcaIcon}
          />
        </Grid>
      }
    </Grid>
  </Grid>, [classes.header, classes.list, classes.root, isFarmaceutico, leftArea, permissoes.temCategorias, permissoes.temEstoques, permissoes.temImpostos, permissoes.temMarcas, permissoes.temModificadores, permissoes.temNCM, permissoes.temProdutos, plano?.plano, planoComEstoque]);

  return component;
};

export default OutrosCadastrosPage;
